<template>
  <v-app :style="settingBackGroundColor()">
    <v-app-bar v-if="$root.isLogin" app dense >
      <v-app-bar-nav-icon v-if="$root.isKyoinMenu" @click.stop="primaryMenu = !primaryMenu">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="accent">mdi-menu</v-icon>
          </template>
          <span>メニューを表示</span>
        </v-tooltip>
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{ this.$root.siteName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" dark @click="this.toProfileSetting">
        設定
      </v-btn>
    </v-app-bar>

    <!-- 教員/管理者メニュー -->
    <v-navigation-drawer v-if="$root.isKyoinMenu" v-model="primaryMenu" fixed temporary>
      <v-list>
        <v-list-item v-for="item in selectedPrimary" :key="item.id" link>
          <!-- サブテキスト設定時 -->
          <v-tooltip right v-if="item.text">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                <v-list-item-title>
                  <v-icon>{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
          
          <!-- サブテキスト未設定時 -->
          <v-list-item-content @click="item.action" v-if="!item.text">
            <v-list-item-title>
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="$root.isKyoinMenu" v-model="healthMenu" fixed temporary>
      <v-list>
        <v-list-item v-for="item in selectedHealth" :key="item.id" link>
          <!-- サブテキスト設定時 -->
          <v-tooltip right v-if="item.text">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                <v-list-item-title>
                  <v-icon>{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
          
          <!-- サブテキスト未設定時 -->
          <v-list-item-content @click="item.action" v-if="!item.text">
            <v-list-item-title>
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="$root.isKyoinMenu" v-model="portfolioMenu" fixed temporary>
      <v-list>
        <v-list-item v-for="item in selectedPortfolio" :key="item.id" link>
          <!-- サブテキスト設定時 -->
          <v-tooltip right v-if="item.text">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                <v-list-item-title>
                  <v-icon>{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
          
          <!-- サブテキスト未設定時 -->
          <v-list-item-content @click="item.action" v-if="!item.text">
            <v-list-item-title>
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <!-- 生徒メニュー -->
    <v-bottom-navigation
      v-if="$root.isStudentMenu"
      color="accent"
      v-model="isActive"
      app
      grow
    >
      <v-btn value="menu01" @click="this.toStudentTopPage">
        <span class="text-button">学校連絡</span>
        <v-icon>mdi-bulletin-board</v-icon>
      </v-btn>
      <v-btn value="menu02" v-if="this.$root.needCourseSelect" @click="this.toCourseSelect">
        <span class="text-button">コース選択</span>
        <v-icon>mdi-routes</v-icon>
      </v-btn>
      <!--<v-btn value="menu03" @click="this.toPoStudyHistory">
        <span class="text-button">学習のきろく</span>
        <v-icon>mdi-treasure-chest</v-icon>
      </v-btn>-->
    </v-bottom-navigation>

    <!-- 保護者メニュー -->
    <v-bottom-navigation
      v-if="$root.isParentMenu"
      color="accent"
      v-model="isActive"
      app
      grow
    >
      <v-btn value="menu01" @click="this.toGuardianTopPage">
        <span class="text-button">学校連絡</span>
        <v-icon>mdi-bulletin-board</v-icon>
      </v-btn>
      <v-btn value="menu02" @click="this.toAttendanceRegist">
        <span class="text-button">出欠連絡</span>
        <v-icon>mdi-chat</v-icon>
      </v-btn>
      <v-btn value="menu03" v-if="this.$root.needCourseSelect" @click="this.toCourseSelectParent">
        <span class="text-button">コース選択確認</span>
        <v-icon>mdi-routes</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- 教員管理者メニュー -->
    <v-bottom-navigation
      v-if="$root.isKyoinMenu"
      color="accent"
      v-model="isActive"
      app
      grow
    >
      <v-btn value="menu01" @click="this.toTeacherTopPage">
        <span class="text-button">校内連絡</span>
        <v-icon>mdi-account-settings-outline</v-icon>
      </v-btn>
      <v-btn value="menu02" @click="this.toAttendanceConfirm">
        <span class="text-button">出欠確認</span>
        <v-icon>mdi-comment-check</v-icon>
      </v-btn>
      <v-btn value="menu03" @click="this.toLeavingConfirm">
        <span class="text-button">早退確認</span>
        <v-icon>mdi-comment-alert</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <Loading
      :active.sync="$root.loading"
      :can-cancel="false"
      :is-full-page="true"
    ></Loading>
  </v-app>
</template>

<style>
</style>
<script>
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
  data() {
    return {
      isActive: 'menu01',
      primaryMenu: false,
      healthMenu: false,
      portfolioMenu: false,
      primaryMenuItems: [
        // 教員メニュー
        {
          id: 0,
          icon: "mdi-email-plus",
          name: "連絡の追加",
          text: "",
          action: this.toContactRegist,
          pattern: 1,
        },
        {
          id: 1,
          icon: "mdi-table-plus",
          name: "アンケートの追加",
          text: "",
          action: this.toSurveyRegist,
          pattern: 1,
        },
        {
          id: 2,
          icon: "mdi-email-edit",
          name: "連絡管理",
          text: "編集や公開状況の確認",
          action: this.toContactList,
          pattern: 1,
        },
        {
          id: 3,
          icon: "mdi-table-edit",
          name: "アンケート管理",
          text: "編集や記入状況の確認",
          action: this.toSurveyList,
          pattern: 1,
        },
        {
          id: 4,
          icon: "mdi-message-cog",
          name: "個別連絡管理",
          text: "参加者の変更や連絡の終了",
          action: this.toTalkRoomList,
          pattern: 1,
        },
        {
          id: 5,
          icon: "mdi-account-multiple-check",
          name: "出欠・早退の追加",
          text: "＋パスワード初期化",
          action: this.toGroupSelect,
          pattern: 1,
        },
        //{
        //  id: 6,
        //  icon: "mdi-folder-multiple-outline",
        //  name: "ポートフォリオ",
        //  text: "課題、活動記録、学習のまとめの管理",
        //  action: this.openPortfolioMenu,
        //  pattern: 1,
        //},
        {
          id: 7,
          icon: "mdi-account-group",
          name: "グループ管理",
          text: "登録やメンバーの割当",
          action: this.toGroupList,
          pattern: 1,
        },
        {
          id: 8,
          icon: "mdi-barcode-scan",
          name: "バーコード受付",
          text: "学生証スキャンによる出欠状況更新",
          action: this.toBarcodeCheckIn,
          pattern: 1,
        },
        {
          id: 9,
          icon: "mdi-database-search-outline",
          name: "出欠集計確認",
          text: "出欠集計(HOME)＋遅刻者一覧表出力",
          action: this.toAttendanceSummary,
          pattern: 1,
        },
        // {
        //   id: 10,
        //   icon: "mdi-human-handsup",
        //   name: "健康管理",
        //   text: "保健室の入退室など",
        //   action: this.openHealthMenu,
        //   pattern: 2,
        // },
      ],
      // 健康メニュー
      healthMenuItems: [
        {
          id: 0,
          icon: "mdi-keyboard-backspace",
          name: "戻る",
          text: "メニューを一つ上の階層に切り替え",
          action: this.openPrimaryMenu,
          pattern: 1,
        },
        {
          id: 1,
          icon: "mdi-medical-bag",
          name: "保健室受付",
          text: "入退室状況の登録・更新",
          action: this.toHealthRoomReception,
          pattern: 1,
        },
      ],
      // ポートフォリオメニュー
      portfolioMenuItems: [
        {
          id: 0,
          icon: "mdi-keyboard-backspace",
          name: "戻る",
          text: "メニューを一つ上の階層に切り替え",
          action: this.openPrimaryMenu,
          pattern: 1,
        },
        {
          id: 1,
          icon: "mdi-folder-plus",
          name: "課題登録と提出状況",
          text: "課題登録と活動記録や学習のまとめの確認",
          action: this.toPoGroupSelect,
          pattern: 1,
        },
        {
          id: 2,
          icon: "mdi-folder-eye",
          name: "課題一覧",
          text: "所属グループまたぎの課題一覧",
          action: this.toPoHomeworkListForSelf,
          pattern: 1,
        },
        {
          id: 3,
          icon: "mdi-update",
          name: "活動記録タイムライン",
          text: "活動記録の提出内容やコメントの確認",
          action: this.toPoActivityRecordTimeline,
          pattern: 1,
        },
        {
          id: 4,
          icon: "mdi-archive-clock",
          name: "学習のまとめタイムライン",
          text: "学習のまとめの提出内容やコメントの確認",
          action: this.toPoStoryTimeline,
          pattern: 1,
        },
      ],
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ログイン済フラグ
    this.$root.isLogin = false;
    // ローディングフラグ
    this.$root.loading = false;
    // 生徒メニュー表示フラグ
    this.$root.isStudentMenu = false;
    // 保護者メニュー表示フラグ
    this.$root.isParentMenu = false;
    // 教員/管理者メニュー表示フラグ
    this.$root.isKyoinMenu = false;
    this.$root.menuPattern = 1;
    // タイトルバーの学校名
    this.$root.siteName = "";
    // コース選択対象者
    this.$root.needCourseSelect = false;
    // 管理者フラグ
    this.$root.adminFlg = false;
    // 被保護者リスト
    this.$root.students = [];
    // アップロード先バケットパス
    this.$root.uploadPath = "";

    // ログイン画面初期表示
    console.log("Vue初期化処理(画面ソース読込)");
    const conds = ['EmailAuthentication', 'PasswordReset'];
    if (!conds.includes(this.$router.currentRoute.name)) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  computed: {
    selectedPrimary() {
      return this.primaryMenuItems.filter(
        (item) => item.pattern <= this.$root.menuPattern
      );
    },
    selectedHealth() {
      return this.healthMenuItems.filter(
        (item) => item.pattern <= this.$root.menuPattern
      );
    },
    selectedPortfolio() {
      return this.portfolioMenuItems.filter(
        (item) => item.pattern <= this.$root.menuPattern
      );
    },
  },
  methods: {
    settingBackGroundColor() {
      switch (this.$root.userKbn) {
        case "0":
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(204, 235, 235),rgb(127, 201, 126));}"
        case "1":
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(255, 248, 194),rgb(255, 147, 75));}"
        case "8":
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(179, 205, 227),rgb(103, 103, 202));}"
        default:
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(255, 248, 194),rgb(255, 147, 75));}"
      }
    },
    // ヘッダーメニュー ---------------------------------------------------------------------------- //
    // プロフィール設定画面
    toProfileSetting() {
      this.isActive = undefined;
      this.$router.push({
        name: "ProfileSetting",
      });
    },
    // フッターメニュー(生徒・保護者用) ---------------------------------------------------------------------------- //
    // 通知連絡画面(生徒用)
    toStudentTopPage() {
      this.$router.push({
        name: "StudentTopPage",
      });
    },
    // 通知連絡画面(保護者用用)
    toGuardianTopPage() {
      this.$router.push({
        name: "GuardianTopPage",
      });
    },
    // 出欠登録/健康報告
    toAttendanceRegist() {
      if(this.$root.students && this.$root.students.length > 1){
        // 被保護者が複数名なら被保護者選択へ
        this.$router.push({
          name: "StudentSelect",
        });
      } else {
        // 被保護者が１名なら出欠登録/健康報告画面へ
        this.$router.push({
          name: "AttendanceRegist",
          params: {
            id: this.$root.students[0],
          }
        });
      }
    },

    // フッターメニュー(教員・管理者用) ---------------------------------------------------------------------------- //
    // 通知連絡画面(教員・管理者用)
    toTeacherTopPage() {
      this.$router.push({
        name: "TeacherTopPage",
      });
    },
    // 出欠確認画面へ
    toAttendanceConfirm() {
      var yyyymmdd = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      this.$router.push({
        name: "AttendanceConfirm",
        params: {
            param_yyyymmdd: yyyymmdd,
        }
      });
    },
    // 早退確認画面へ
    toLeavingConfirm() {
      var yyyymmdd = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      this.$router.push({
        name: "LeavingConfirm",
        params: {
            param_yyyymmdd: yyyymmdd,
        }
      });
    },


    // サイドメニュー(教員・管理者用) ---------------------------------------------------------------------------- //
    // 連絡登録
    toContactRegist() {
      this.isActive = undefined;
      this.$router.push({
        name: "ContactRegist",
      });
    },
    // アンケート登録
    toSurveyRegist() {
      this.isActive = undefined;
      this.$router.push({
        name: "SurveyRegist",
      });
    },

    // 連絡一覧画面 ⇒ 連絡訂正・未読/既読確認
    toContactList() {
      this.isActive = undefined;
      this.$router.push({
        name: "ContactList",
      });
    },
    // アンケート一覧画面 ⇒ アンケート訂正・未読/既読確認
    toSurveyList() {
      this.isActive = undefined;
      this.$router.push({
        name: "SurveyList",
      });
    },
    // 個別連絡一覧
    toTalkRoomList() {
      this.isActive = undefined;
      this.$router.push({
        name: "TalkRoomList",
      });
    },
    // グループ選択 ⇒ 生徒一覧 ⇒ 出欠訂正 or 早退登録
    toGroupSelect() {
      this.isActive = undefined;
      this.$router.push({
        name: "GroupSelect",
      });
    },
    // グループ設定画面
    toGroupList() {
      this.isActive = undefined;
      this.$router.push({
        name: "GroupList",
      });
    },
    // バーコード受付画面
    toBarcodeCheckIn() {
      this.isActive = undefined;
      this.$router.push({
        name: "BarcodeCheckIn",
      });
    },
    // 活動記録タイムラインへ
    toPoActivityRecordTimeline() {
      this.isActive = undefined;
      this.$router.push({
        name: "PoActivityRecordTimeline",
      });
    },
    // 学習のまとめタイムラインへ
    toPoStoryTimeline() {
      this.isActive = undefined;
      this.$router.push({
        name: "PoStoryTimeline",
      });
    },
    // 課題グループ選択
    toPoGroupSelect() {
      this.isActive = undefined;
      this.$router.push({
        name: "PoGroupSelect",
      });
    },
    // 課題一覧
    toPoHomeworkListForSelf() {
      this.isActive = undefined;
      this.$router.push({
        name: "PoHomeworkListForSelf",
      });
    },
    // 出欠集計確認
    toAttendanceSummary() {
      this.isActive = undefined;
      this.$router.push({
        name: "AttendanceSummary",
      });
    },
    // 保健室受付
    toHealthRoomReception() {
      this.isActive = undefined;
      this.$router.push({
        name: "HealthRoomReception",
      });
    },
    // コース選択
    toCourseSelect() {
      this.$router.push({
        name: "CourseSelect",
      });
    },
    // 学習のきろく
    toPoStudyHistory() {
      this.$router.push({
        name: "PoStudyHistory",
      });
    },
    // コース選択確認
    toCourseSelectParent() {
      this.$router.push({
        name: "CourseSelectParent",
      });
    },
    // 健康メニューを開く
    openHealthMenu() {
      this.primaryMenu = false;
      this.healthMenu = true;
    },
    // 課題メニューを開く
    openPortfolioMenu() {
      this.primaryMenu = false;
      this.portfolioMenu = true;
    },
    // 上階層に戻る
    openPrimaryMenu() {
      this.healthMenu = false;
      this.portfolioMenu = false;
      this.primaryMenu = true;
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@500&display=swap');
#app {
  font-family: "Zen Kaku Gothic New", sans-serif;
}
</style>